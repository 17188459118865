import React from 'react';

function ErrorPage(props) {
  return (
    <>
      error page
    </>
  );
}

export default ErrorPage;
