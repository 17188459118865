import React from 'react';

function UpdatePage(props) {
  return (
    <>
      update page
    </>
  );
}

export default UpdatePage;
